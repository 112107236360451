/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
// App Global Sass
// --------------------------------------------------
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.

ion-icon {
  font-size: 1rem;
}

a, button {
  cursor: pointer !important;
}

.app-header {
	font-size: 20px;
	color: var(--ion-color-light);
	background-color: var(--ion-color-primary);
	text-transform: none;
}

.app-button {
	font-size: 20px;
	color: var(--ion-color-light);
	background-color: var(--ion-color-primary);
	text-transform: none;
}

.app-button-danger {
	font-size: 20px;
	color: var(--ion-color-light);
	background-color: var(--ion-color-danger);
	text-transform: none;
}

.error-box {
	padding: 8px;
	background-color: 1px solid var(--ion-color-danger);
	color: var(--ion-color-dark);
	background-color: var(--ion-color-danger);
	border-radius: 4px;
	margin: 10px;
	p {
		color: var(--ion-color-light);
		margin: 0px;
	}
}

.button-page {
	background-color: var(--ion-color-light-tint);
}

.label-md {
	padding: 0.2em 0em 0em 0em;
}

.md ion-footer .toolbar:last-child {
	min-height: 12px;
}

.background-day {
	background-image: linear-gradient(#36a6b4, #d4a385);
}

 .background-night {
	background-image: linear-gradient(#33668e, #f0bf69);
}

 .background-dif {
	background-image: linear-gradient(#7bc6d6, #f0dcc4);
}

 .background-off {
	background-color: black;
}

 .background-environment {
	background-color: var(--ion-color-environment);
}

 .background-irrigation {
	background-color: var(--ion-color-irrigation);
}

.content, .content-md, .content-ios {
	background-color: var(--ion-color-background-gray);

	a {
		color: var(--ion-color-primary);
		font-weight: 500;
    cursor: pointer;
	}

  input[type="submit"] {
    cursor: pointer;
  }

  button {
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

	h1 {
		font-size: 3.5rem;
	}

	h3 {
		font-size: 1.5rem;
	}

	h4 {
		font-size: 1.25rem;
	}

	form {
		ion-item {
			margin: 0.5em 0;
			border: 0.5px solid var(--ion-color-outline-gray);

			.item-inner {
				border-bottom: 0;
			}
		}
	}

	.item-input ion-label {
	  pointer-events:all!important;
	}
}

* {
	font-family: 'Montserrat', sans-serif;
}

ion-footer {
	font-size: 10px;
	color: var(--ion-color-light);
	margin: 0px;
	padding: 0px;

	ion-toolbar {
		min-height: 10px;

		ul {
			list-style: none;
			float: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      max-width: 95%;
      margin: 0 2.5%;
      padding: 0;

			li {
				display: inline-block;
				float: left;
        text-align: center;
        padding: 0 0.5em;

				&:last-of-type {
					padding-left: 2.5em;
				}
			}
		}
	}
}

.toolbar-content {
  display: flex;
  align-items: center;

  img {
    flex: 1 1 auto;
    flex-shrink: 1;
    max-width: 3em;
  }

  ion-title {
    flex-grow: 10;
  }
}

ion-title, .title-ios, .title-default {
  @media(min-width: 767px) {
    text-align: left !important;
    justify-content: space-between;
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    > span {
      flex: 0 1 100%;
      text-align: center;

      &.both-buttons {
        flex: 0 1 75%;
      }

      &.one-button {
        flex: 0 1 85%;
      }
    }
    > button {
      flex: 1 0 20%;
    }
  }

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  button {
    height: 100% !important;
    background: none;
    color: var(--ion-color-light);

    &:first-of-type {
      margin-right: 1em;
    }

    &:last-of-type {
      margin-left: 1em;
    }
  }
}

@media screen and (min-width: 768px) {
	.toolbar-content {
	  display: flex;
	  align-items: center;

	  img {
	    flex: auto;
	    flex-shrink: 10;
			max-width: 60px;
	  }

	  ion-title {
	    flex-grow: 20;
			flex: auto;
	  }
	}
}

.success {
  .toast-message {
    background-color: var(--ion-color-secondary-blue);
    color: var(--ion-color-light);
  }
}

div.popover-content, div.sc-ion-popover-md, div.sc-ion-popover-ios {
	@media (max-width: 820px) {
		left: 0 !important;
    top: 0 !important;
    padding-top: env(--ion-statusbar-padding);
    // padding-top: calc(--ion-statusbar-padding - 5px);
	}

  @media (min-width: 820px) {
    padding-top: env(--ion-statusbar-padding);
    // padding-top: calc(--ion-statusbar-padding - 5px);
	}

  app-edit-vent-setting, app-edit-setting, app-edit-heat-cool-setting, app-wind-rain-edit, app-programming-tools-page {
    height: 100%;
    overflow: hidden;

    .content {
      height: 93%;
      max-height: 93%;
      overflow: scroll;
      padding: 1em;

      form {
        height: 100%;
      }
    }
  }

  .inner-scroll {
    height: 100%;
    border: 4px solid pink;
  }

	// ion-content {
	// 	--padding-start: 0.5em;
	// 	--padding-end: 0.5em;
    // height: 100%;
    // overflow: scroll;
	// }
}

ion-icon {
	&.changing {
		color: var(--ion-color-secondary) !important;
		// --color: var(--ion-color-secondary) !important;
		svg {
			fill: var(--ion-color-secondary) !important;
		}
	}

	&.temp {
		color: var(--ion-color-secondary-blue) !important;
		// --color: var(--ion-color-secondary-blue) !important;
		svg {
			fill: var(--ion-color-secondary-blue) !important;
		}
	}

	&.warning, &.offline {
		color: var(--ion-color-danger) !important;
		// --color: var(--ion-color-danger) !important;
		svg {
			fill: var(--ion-color-danger) !important;
		}
	}
}

.width-100 {
	width: 100%;
	padding: 0 0.5em !important;
	display: flex;
	justify-content: space-between;
	flex-direction: row !important;

	label, ion-label {
		width: 60%;
		display: flex;
		margin: 0 !important;
		padding: 0;
	}

	select, input, ion-select {
		width: 40%;
		margin: 0 !important;
		padding: 0;
	}
}

.settingsButton {
  display: none;

  @media(min-width: 820px) {
    display: flex;
  }
}

.alert-wrapper {
	.alert-message {
		font-size: 0.95em;
	}

  .alert-button {
		background-color: var(--ion-color-secondary-blue) !important;
		color: var(--ion-color-white) !important;
		margin: 0.5em;

    &.secondary {
      background-color: var(--ion-color-medium) !important;
    }

    &.primary {
      background-color: var(--ion-color-primary) !important;
    }
  }
}

input[type="time"]::-webkit-clear-button {
    display: none;
}

ion-toolbar {
  ion-buttons {
    ion-button.go-back {
      --padding-start: 0.5em !important;
      --padding-end: 0.5em !important;

      @media(min-width: 820px) {
        --padding-start: 1em !important;
        --padding-end: 1em !important;
      }
    }
  }
}

.toast-error {
  padding: 1em;
  font-weight: bold;
  font-size: 1.25em;
  --background: var(--ion-color-danger);
  --color: var(--ion-color-light);
}

.toast-success {
  padding: 1em;
  font-weight: bold;
  font-size: 1.25em;
  --background-color: var(--ion-color-primary);
  --color: var(--ion-color-light);
}

.save-icon {
  width: 1.5em;
  height: 1.5em;
}

app-new {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
