// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family: 'Montserrat', sans-serif;
  /** primary **/
  --ion-color-primary: #5CBF91;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #def2e9;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #408666;
  --ion-color-primary-tint: #7dcca7;

  --ion-color-secondary-blue: #5DA2BF;
  --ion-color-secondary-blue-rgb: 93, 162, 191;
  --ion-color-secondary-blue-tint: #a0c8d9;
  --ion-color-secondary-blue-dark: #4085a2;
  --ion-color-secondary-blue-light: #8dbdd2;

  --ion-color-gray: #F2F2F2;
  --ion-color-background-gray: #FCFCFC;
  --ion-color-background-gray-rgb: 30, 45, 91;
  --ion-color-background-gray-dark: #858585;
  --ion-color-outline-gray-tint: #c8c8c8;

  --ion-color-status-orange: #e65722;
  --ion-color-wind-rain-on: #f09941;
  --ion-color-wind-rain-on-rgb: 240, 153, 65;
  --ion-color-status-blue: #4183D7;

  /** secondary **/
  --ion-color-secondary: #cc9000;
  --ion-color-secondary-rgb: 209, 155, 26;
  --ion-color-secondary-contrast: #f0deb3;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #8f6500;
  --ion-color-secondary-tint: #e0bc66;
  --ion-color-secondary-light-7: #e19f00;
  --ion-color-secondary-light-5: #db9a00;

  /** tertiary **/
  --ion-color-tertiary: #a968bb;
  --ion-color-tertiary-rgb: 169,104,187;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #955ca5;
  --ion-color-tertiary-tint: #b277c2;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f9f9f9;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-color-light-darkened: #f2f2f2;

  --ion-color-white: #ffffff;

  --ion-padding-start: 1em;
  --ion-padding-end: 1em;

  ion-popover {
    --min-height: 100%;
    --height: 100%;
    --width: 100%;
    --min-width: 95%;
    --backdrop-opacity: 0;
    left: 0;
    border: 1px solid var(--ion-color-dark);
    // overflow: hidden;

    @media (min-width: 820px) {
      --width: 60%;
      --min-width: 60%;
      --height: 90%;
      --min-height: 85%;
    }

    &.settings-list-popover {
      --width: 20% !important;
      --min-width: 20% !important;

      .popover-content {
        position: absolute;
        left: 80% !important;
        transform-origin: right !important;
        text-align: center;
        height: 100%;

        ul {
          list-style: none;
          padding: 1em;

          li {
            color: var(--ion-color-secondary-blue);
            text-decoration: underline;
            font-size: 1.2em;
            margin: 0.5em 0;
          }
        }
      }
    }
  }

  ion-toolbar {
    --color: var(--ion-color-light);
  }

  ion-back-button {
    .button-text { display: none; }
  }
}

@media (prefers-color-scheme: dark) {
}

.ios body.dark, .md body.dark, body.dark {
  --ion-font-family: 'Montserrat', sans-serif;
  /** primary **/
  --color: #f9f9f9;
  --ion-color-primary: #408666;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #9dd9bd;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #aedfc8;
  --ion-color-primary-tint: #53ac83;

  --ion-color-secondary-blue: #417186;
  --ion-color-secondary-blue-rgb: 65, 113, 134;
  --ion-color-secondary-blue-tint: #5492ac;
  --ion-color-secondary-blue-dark: #5da2bf;
  --ion-color-secondary-blue-light: #9ec7d9;

  --ion-color-background-gray: #1e2d5b;
  --ion-color-background-gray-rgb: 30, 45, 91;
  --ion-color-background-gray-dark: #78819d;
  --ion-color-outline-gray-tint: #c8c8c8;

  --ion-color-status-orange: #e65722;
  --ion-color-wind-rain-on: #f09941;
  --ion-color-wind-rain-on-rgb: 240, 153, 65;
  --ion-color-status-blue: #4183D7;

  /** secondary **/
  --ion-color-secondary: #d19b1a;
  --ion-color-secondary-rgb: 209, 155, 26;
  --ion-color-secondary-contrast: #e0bc66;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #a37300;
  --ion-color-secondary-tint: #b88200;
  --ion-color-secondary-light-7: #d6a633;
  --ion-color-secondary-light-5: #d6a633;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f26449;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #f7a292;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #a94633;
  --ion-color-danger-tint: #c2503a;

  /** dark **/
  --ion-color-dark: #f9f9f9;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #ebf6f8;
  --ion-color-dark-tint: #f2f9fb;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #4b577c;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #a5abbd;
  --ion-color-light-tint: #8f96ad;
  --ion-color-light-darkened: #78819d;

  --ion-color-white: #4b577c;

  --ion-padding-start: 1em;
  --ion-padding-end: 1em;

  ion-popover {
    --min-height: 100%;
    --height: 100%;
    --width: 100%;
    --min-width: 95%;
    --backdrop-opacity: 0;
    left: 0;
    border: 1px solid var(--ion-color-light);
    // overflow: hidden;

    @media (min-width: 820px) {
      --width: 60%;
      --min-width: 60%;
      --height: 90%;
      --min-height: 85%;
    }
  }

  ion-content {
    --color: #f9f9f9;

    a {
      --color: #f9f9f9 !important;
      color: #f9f9f9 !important;
    }
  }

  ion-item {
    --background-color: #4b577c;
    --color: #f9f9f9;
  }

  input {
    background-color: #4b577c;
    color: #f9f9f9;
    --color: #f9f9f9;
  }

  ::placeholder {
    color: var(--ion-color-light-tint);
  }

  app-node-list {
    .node-name, .title {
      color: var(--ion-color-dark) !important;
    }
  }

  button {
    // background-color: var(--ion-color-dark-shade);
    color: var(--ion-color-light) !important;
  }

  ion-back-button {
    button { background-color: var(--ion-color-primary) !important; }
    .button-text { display: none; }
  }

  ion-toolbar ionic-selectable {
    ion-item-sliding, ion-item, ion-label {
      background: none;
      --background: none;
    }
    button {
      --background: var(--ion-color-primary-tint) !important;
    }

    .ionic-selectable-value-item, .ionic-selectable-icon {
      color: var(--ion-color-dark);
    }
  }

  ion-icon, ion-button, .green-button, .settings-header, .admin-box, ion-toolbar {
    --color: #f9f9f9 !important;
    color: #f9f9f9 !important;
    background-color: none !important;

    &.primary {
      --background-color: var(--ion-color-secondary);
    }

    &.secondary {
      --background-color: var(--ion-color-medium);
    }
  }

  app-edit-time-boss {
    h6, label, p {
      color: var(--ion-color-dark);
    }

    input {
      border: 0;
    }
  }

  #emails > p {
    color: var(--ion-color-secondary-blue-light) !important;
    --color: var(--ion-color-secondary-blue-light) !important;
  }

  .timeboss-timer {
    .stageA {
      border: 1px solid var(--ion-color-tertiary-tint) !important;
      div:last-of-type { border-right: 1px solid var(--ion-color-tertiary-tint) !important; }
      p {
        background-color: var(--ion-color-tertiary-tint) !important;
      }
    }

    .stageB {
      border: 1px solid var(--ion-color-danger) !important;
      div:last-of-type { border-right: 1px solid var(--ion-color-danger) !important; }
      p {
        background-color: var(--ion-color-danger) !important;
      }
    }
  }

  #zone {
    .main-heading, .main, .current, timer-mister, .dehumidification, .timeboss-timer {
      background-color: var(--ion-color-white) !important;
    }

    .options, .timer, .mister, .dehumidification {
      background-color: var(--ion-color-light) !important;
    }

    .temp-and-set > .temp > p { color: #f9f9f9 !important; }

    &.nite {
      background: linear-gradient(#1b2952, #604c2a) !important;
    }

    &.dif {
      background: linear-gradient(#253b40, #302c27) !important;
    }

    &.day {
      background: linear-gradient(#164248, #2a211b) !important;
    }
  }

  .last-update a {
    color: var(--ion-color-secondary-blue-light) !important;
  }

  .status-box {
    &.cool-on, &.heat-on {
      color: #fff !important;
    }
  }

  .subscription-setup {
    ion-item {
      border-bottom: 1px solid var(--ion-color-dark);
    }

    input {
      background-color: var(--ion-color-background-gray) !important;
    }
  }

  app-contact-us {
    #emails p {
      color: var(--ion-color-secondary-blue-light);
    }
  }

  app-notifications, app-edit-subscription {
    .device-details-header span {
      color: var(--ion-color-secondary);
      font-weight: 500;
    }

    button {
      padding: 0.5em;
      color: var(--ion-color-light) !important;
    }
  }

  .alert-wrapper {
    #alert-1-hdr, .alert-radio-label, .alert-button {
      color: var(--ion-color-dark) !important;
    }
  }

  ion-icon {
    color: var(--ion-color-light);
  }
}
